import React, { useState, useEffect } from 'react'
import { Checkbox, Radio, Box, Typography, styled } from '@mui/material'

const StyledCheckbox = styled(Checkbox)({
  '&.MuiCheckbox-root, &.MuiRadio-root': {
    'padding': '0 16px 0 0', // Removendo o padding
    'margin': '0', // Removendo o margin
    '& svg': {
      color: '#EEEEEE',
      height: '20px',
      width: '20px',
    },
  },
  '&.MuiCheckbox-root.Mui-checked, &.MuiRadio-root.Mui-checked': {
    '& svg': {
      color: '#00B2FF',
      height: '20px',
      width: '20px',
    },
  },
})

const StyledRadio = styled(Radio)({
  '&.MuiRadio-root, &.MuiRadio-root': {
    'padding': '0 16px 0 0', // Removendo o padding
    'margin': '0', // Removendo o margin
    '& svg': {
      color: '#EEEEEE',
      height: '20px',
      width: '20px',
    },
  },
  '&.MuiRadio-root.Mui-checked, &.MuiRadio-root.Mui-checked': {
    '& svg': {
      color: '#00B2FF',
      height: '20px',
      width: '20px',
    },
  },
})

const TGCheckbox = ({
  options,
  onChange,
  selectedValues,
  isRadio,
  isCircular,
  gap,
  maxWidth = '85%',
  errorMessage,
  warning,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(selectedValues || [])

  useEffect(() => {
    setSelectedOptions(selectedValues || [])
  }, [selectedValues])

  const handleCheckboxChange = (optionValue) => {
    const updatedSelection = [...selectedOptions]

    if (isRadio) {
      // Se for um Radio, substitua a seleção existente
      updatedSelection.splice(0, updatedSelection.length, optionValue)
    } else {
      // Se for um Checkbox, adicione/remova da seleção
      if (updatedSelection.includes(optionValue)) {
        updatedSelection.splice(updatedSelection.indexOf(optionValue), 1)
      } else {
        updatedSelection.push(optionValue)
      }
    }

    setSelectedOptions(updatedSelection)
    onChange(updatedSelection)
  }

  return (
    <Box display={'flex'} flexDirection={'column'} gap={gap}>
      {options.map((item) => (
        <Box display='flex' alignItems='flex-start' key={item.value}>
          {isCircular ? (
            <StyledRadio
              checked={selectedOptions.includes(item.value)}
              onChange={() => handleCheckboxChange(item.value)}
              sx={{ '&.MuiCheckbox-root': { borderRadius: '8px' } }}
              disabled={item.disabled}
            />
          ) : (
            <StyledCheckbox
              checked={selectedOptions.includes(item.value)}
              onChange={() => handleCheckboxChange(item.value)}
              sx={{ '&.MuiCheckbox-root': { borderRadius: '8px' } }}
              disabled={item.disabled}
            />
          )}

          <Box>
            <Typography
              fontSize={'0.875rem'}
              color={item.disabled ? '#999999' : '#222'}
            >
              {item.label}{' '}
              {item.disabled && (
                <Typography
                  display={'inline'}
                  fontSize={'0.875rem'}
                  color='#00B2FF'
                  fontWeight={'500'}
                >
                  Em breve!
                </Typography>
              )}
            </Typography>
            {item.text && (
              <Typography
                fontSize={'0.75rem'}
                color={item.disabled ? '#c3c3c3' : '#999999'}
                mb={3}
                maxWidth={maxWidth}
              >
                {item.text}
              </Typography>
            )}
          </Box>
        </Box>
      ))}
      {errorMessage && (
        <Typography
          color='#FF5858'
          lineHeight={'15px'}
          fontSize={'10px'}
          fontWeight={'400'}
        >
          {errorMessage}
        </Typography>
      )}
    </Box>
  )
}

export default TGCheckbox
