import {
  Box,
  Divider,
  Fade,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import * as React from 'react'
import { NavLink } from 'react-router-dom'
import exit from 'assets/icons/exit.svg'

import { ArrowDown, ArrowUp, hamburguerDash, help } from 'assets/svgs'

const submenuItems = [{ text: 'Ajuda e suporte', icon: help, to: '/' }]

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: ' 0 2rem',
  ...theme.mixins.toolbar,
}))

const SubMenu = ({ children, open }) => {
  if (!open) return null

  return (
    <List
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0',
        padding: '2px',
        px: 0.5,
      }}
    >
      {children.map((subitem) => (
        <Fade in={open} mountOnEnter unmountOnExit>
          <ListItem key={subitem.text} disablePadding>
            <ListItemButton
              className='submenu-items-desk'
              component={NavLink}
              to={subitem.to}
              activeClassName='active'
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                pl: 5,
                position: 'relative',
              }}
            >
              <ListItemText
                primary={subitem.text}
                sx={{ opacity: open ? 1 : 0 }}
              />
              <span
                className='submenu-span'
                style={{
                  position: 'absolute',
                  left: '16px',
                  fontSize: '1rem',
                }}
              >
                •
              </span>
            </ListItemButton>
          </ListItem>
        </Fade>
      ))}
    </List>
  )
}

export default function TGSideBarMobile({ menuItems, setisOpen2 }) {
  const [open, setOpen] = React.useState(true)
  const [subMenuOpen, setSubMenuOpen] = React.useState(() => {
    const initialSubMenuOpen = {}
    menuItems.forEach((item, index) => {
      if (item.children) {
        initialSubMenuOpen[index] = false
      }
    })
    return initialSubMenuOpen
  })

  const handleDrawerClose = () => {
    setOpen(false)
    setSubMenuOpen({})
    setisOpen2(false) // Altera a variável isOpen no componente pai
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleSubMenuToggle = (index) => {
    setSubMenuOpen((prev) => {
      const updatedSubMenuOpen = { [index]: !prev[index] }

      // Fechar todos os outros submenus
      Object.keys(prev).forEach((key) => {
        if (key !== index && prev[key]) {
          updatedSubMenuOpen[key] = false
        }
      })

      return updatedSubMenuOpen
    })

    setOpen(true)
  }

  const renderMenuItem = (item, index) => (
    <React.Fragment key={item.text}>
      <ListItem disablePadding>
        <ListItemButton
          className='sidebar-menu'
          component={item.children ? 'div' : NavLink} // Use 'div' como componente se houver children
          to={item.children ? null : item.to} // Defina null como 'to' se houver children
          activeClassName='active'
          onClick={
            item.children
              ? () => handleSubMenuToggle(index)
              : () => setisOpen2(false)
          } // Manipulador apenas se houver children
          sx={{
            minHeight: 48,
            justifyContent: open ? 'initial' : 'center',
            px: open ? 1.5 : 2.5,
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: open ? '12px' : 'auto',
              justifyContent: 'center',
            }}
          >
            <img src={item.icon} alt={item.text} width='24' height='24' />
          </ListItemIcon>

          <ListItemText primary={item.text} sx={{ opacity: open ? 1 : 0 }} />
          {item.isBeta && (
            <Typography
              sx={{
                background: 'linear-gradient(to right, #00B2FF, #00FF85)',
              }}
              borderRadius={'4px'}
              padding='1px 6px'
              fontSize={'10px'}
              color='#fafafa'
              marginRight={'8px'}
            >
              Beta
            </Typography>
          )}
          {item.children && open && (
            <div>
              {subMenuOpen[index] ? (
                <img src={ArrowUp} alt='seta-pra-cima' />
              ) : (
                <img src={ArrowDown} alt='seta-pra-baixo' />
              )}
            </div>
          )}
        </ListItemButton>
      </ListItem>

      <Box onClick={() => setisOpen2(false)}>
        <SubMenu children={item.children} open={subMenuOpen[index]} />
      </Box>
    </React.Fragment>
  )

  return (
    <SwipeableDrawer
      anchor='left'
      onClose={handleDrawerClose}
      ModalProps={{
        keepMounted: true,
        position: 'fixed',
      }}
      open={open}
      sx={{
        zIndex: 999999,
        display: { xs: 'flex', lg: 'none' },
        padding: '28px',
        '.MuiDrawer-root .MuiListItemButton-root:hover': {
          backgroundColor: '#00b2ff',
          borderRadius: '8px',
        },
      }}
    >
      <DrawerHeader
        sx={{ justifyContent: !open ? 'center' : '', paddingTop: '14px' }}
      >
        {open && (
          <Box
            display='flex'
            alignItems='center'
            width='100%'
            color='#222'
            justifyContent={'space-between'}
          >
            <IconButton
              size='medium'
              edge='start'
              color='inherit'
              aria-label='open drawer'
              onClick={() => setisOpen2(false)}
              sx={{ display: { xs: 'block', lg: 'none', ml: 3 } }}
            >
              <img src={exit} alt='dragimg' height={'18px'} width={'18px'} />
            </IconButton>
          </Box>
        )}
        <IconButton
          color='inherit'
          aria-label='open drawer'
          onClick={handleDrawerOpen}
          edge='start'
          sx={{
            marginLeft: 'auto',
            ...(open && { display: 'none' }),
          }}
        >
          <img src={hamburguerDash} alt='open-and-close-icon' />
        </IconButton>
      </DrawerHeader>

      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          marginBottom: '32px',
          paddingInline: open ? '20px' : '8px',
        }}
      >
        {menuItems.map((item, index) => renderMenuItem(item, index))}
      </List>

      <Divider
        variant='middle'
        sx={{
          backgroundColor: '##EEE',
          opacity: 0.1,
        }}
      />

      <List
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          paddingInline: open ? '20px' : '8px',
        }}
      >
        {submenuItems.map((item) => (
          <ListItem key={item.text} disablePadding>
            <ListItemButton
              component={NavLink}
              to={item.to}
              activeClassName='active'
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 1.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 1 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <img src={item.icon} alt={item.text} width='24' height='24' />
              </ListItemIcon>
              <ListItemText
                primary={item.text}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </SwipeableDrawer>
  )
}
