// menuItemsProduct.js
import { pixel } from 'assets/svgs'
import { cooproducao } from 'assets/svgs'
import {
  resumo,
  paginaVenda,
  ofertas,
  cupom,
  listaMembros,
  gerenciarTurmas,
} from 'assets/svgs'

const menuItemsProduct = [
  {
    text: 'Resumo',
    icon: resumo,
    to: 'produto/resumo',
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN', 'PRODUTOR-FINANCEIRO'],
    adm: true,
  },
  //{
  //  text: 'Página de vendas',
  //  icon: paginaVenda,
  //  to: 'produto/add',
  //  roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN', 'PRODUTOR-FINANCEIRO'],
  //  adm: true,
  //},
  {
    text: 'Ofertas',
    icon: ofertas,
    to: 'produto/ofertas',
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN', 'PRODUTOR-FINANCEIRO'],
    adm: true,
  },
  {
    text: 'Cupom',
    icon: cupom,
    to: 'produto/cupons',
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN', 'PRODUTOR-FINANCEIRO'],
    adm: true,
  },
  {
    text: 'Lista de membros',
    icon: listaMembros,
    to: 'produto/membros',
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN', 'PRODUTOR-FINANCEIRO'],
    adm: true,
  },
  {
    text: 'Gerenciar turma',
    icon: gerenciarTurmas,
    to: 'produto/turma',
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN', 'PRODUTOR-FINANCEIRO'],
    adm: true,
  },
  {
    text: 'Cooprodução',
    icon: cooproducao,
    to: 'produto/coproducao',
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN', 'PRODUTOR-FINANCEIRO'],
    adm: true,
  },
  {
    text: 'Pixel',
    icon: pixel,
    to: 'produto/rastreamento',
    roles: ['PRODUTOR-OPERACIONAL', 'PRODUTOR-ADMIN', 'PRODUTOR-FINANCEIRO'],
    adm: true,
  },
]

export default menuItemsProduct
