export default function TGIcon({
  src,
  height = '32px',
  width = '32px',
  alt = 'defaultAlt',
  onClick,
}) {
  return (
    <img src={src} height={height} width={width} alt={alt} onClick={onClick} />
  )
}
