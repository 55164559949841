import React from 'react'
import Select from './Select';
import items from '../../utils/banks-list'

const BankSelect = ({ label = 'Banco', classe, name, errors = {} }) => {

    

    return (
        <Select errors={errors} label={label} name={name} className={`form-select ${classe == 'normal' ? 'form-select-normal' : ''}`} >
            <option  value="">-- Selecione --</option>
            {
                items.map(item => (
                    <option key={item.Code} value={item.Code}>{item.Name}</option>
                ))
            }
        </Select>
    )
}

export default BankSelect