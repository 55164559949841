const appConfig = {

  env: 'development',

  production: {

    host: 'https://api-reteste-v1.ticketandgo.com.br',

    socketUrl: 'https://api-reteste-v1.ticketandgo.com.br',

    api: 'https://api-reteste-v1.ticketandgo.com.br',

    apiv2: 'https://api-reteste-v2.jelastic.saveincloud.net',

    apiCheckout: 'https://checkout-reteste.ticketandgo.com.br',

  },

  development: {


    host: 'https://api-reteste-v1.ticketandgo.com.br',

    socketUrl: 'https://api-reteste-v1.ticketandgo.com.br',

    api: 'https://api-reteste-v1.ticketandgo.com.br',

    apiv2: 'https://api-reteste-v2.jelastic.saveincloud.net',

    apiCheckout: 'https://checkout-reteste.ticketandgo.com.br',

  },

};



export default appConfig;


