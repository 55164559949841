import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Fade,
  Stack,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import * as React from 'react'
import { NavLink, useSearchParams } from 'react-router-dom'

import { ArrowDown, ArrowUp, hamburguerDash, help } from 'assets/svgs'
import { Drawer, TGTooltip } from './styles'
import menuItemsProduct from 'data/navs/MenuItemsProduct'

const submenuItems = [
  {
    text: 'Ajuda e suporte',
    icon: help,
    to: 'https://ajuda.ticketandgo.com.br/pt-br/',
  },
]

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: ' 0 2rem',
  ...theme.mixins.toolbar,
}))

const SubMenu = ({ children, open }) => {
  if (!open) return null

  return (
    <List
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '0',
        padding: '2px',
        px: 0.5,
      }}
    >
      {children.map((subitem) => (
        <Fade in={open} mountOnEnter unmountOnExit>
          <ListItem key={subitem.text} disablePadding>
            <ListItemButton
              className='submenu-items-desk'
              component={NavLink}
              to={subitem.to}
              activeClassName='active'
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                pl: 5,
                position: 'relative',
              }}
            >
              <ListItemText
                primary={subitem.text}
                sx={{ opacity: open ? 1 : 0 }}
              />
              <span
                className='submenu-span'
                style={{
                  position: 'absolute',
                  left: '16px',
                  fontSize: '1rem',
                }}
              >
                •
              </span>
            </ListItemButton>
          </ListItem>
        </Fade>
      ))}
    </List>
  )
}

export default function TGSideBar({ menuItems }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const slug = searchParams.get('slug')
  const menuTipo = searchParams.get('menuTipo')

  const [open, setOpen] = React.useState(true)
  const [subMenuOpen, setSubMenuOpen] = React.useState(() => {
    const initialSubMenuOpen = {}
    menuItems.forEach((item, index) => {
      if (item.children) {
        initialSubMenuOpen[index] = false
      }
    })
    return initialSubMenuOpen
  })

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
    setSubMenuOpen({})
  }

  const handleSubMenuToggle = (index) => {
    setSubMenuOpen((prev) => {
      const updatedSubMenuOpen = { [index]: !prev[index] }

      // Fechar todos os outros submenus
      Object.keys(prev).forEach((key) => {
        if (key !== index && prev[key]) {
          updatedSubMenuOpen[key] = false
        }
      })

      return updatedSubMenuOpen
    })

    setOpen(true)
  }

  const renderMenuItem = (item, index) => (
    <React.Fragment key={item.text}>
      <ListItem disablePadding>
        <TGTooltip
          title={item.text}
          placement='right'
          enterTouchDelay={0}
          disableHoverListener={open}
        >
          <ListItemButton
            className='sidebar-menu'
            component={item.children ? 'div' : NavLink} // Use 'div' como componente se houver children
            to={
              item.children
                ? null
                : menuTipo === 'produto'
                ? `${item.to}?menuTipo=produto&slug=${slug}`
                : item.to
            }
            onClick={item.children ? () => handleSubMenuToggle(index) : null} // Manipulador apenas se houver children
            sx={{
              'minHeight': 48,
              'justifyContent': open ? 'initial' : 'center',
              'px': open ? 1.5 : 2.5,
              '&:active': {
                backgroundColor: 'red',
              },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? '12px' : 'auto',
                justifyContent: 'center',
              }}
            >
              <img src={item.icon} alt={item.text} width='24' height='24' />
            </ListItemIcon>
            <ListItemText primary={item.text} sx={{ opacity: open ? 1 : 0 }} />
            {item.isBeta && open && (
              <Typography
                sx={{
                  background: 'linear-gradient(to right, #00B2FF, #00FF85)',
                }}
                borderRadius={'4px'}
                padding='1px 6px'
                fontSize={'10px'}
                color='#fafafa'
                marginRight={'10px'}
              >
                Beta
              </Typography>
            )}

            {item.children && open && (
              <div>
                {subMenuOpen[index] ? (
                  <img src={ArrowUp} alt='seta-pra-cima' />
                ) : (
                  <img src={ArrowDown} alt='seta-pra-baixo' />
                )}
              </div>
            )}
          </ListItemButton>
        </TGTooltip>
      </ListItem>

      <SubMenu children={item.children} open={subMenuOpen[index]} />
    </React.Fragment>
  )

  return (
    <Drawer
      variant='permanent'
      ModalProps={{
        keepMounted: true,
        position: 'fixed',
      }}
      open={open}
      sx={{
        'zIndex': 999,
        'display': { xs: 'none', lg: 'block' },
        'padding': '28px',
        '.MuiDrawer-root .MuiListItemButton-root:hover': {
          backgroundColor: '#00b2ff',
          borderRadius: '8px',
        },
      }}
    >
      <Box
        sx={{
          'maxHeight': `calc(100vh - 120px)`,
          'overflowY': 'auto',
          'overflowX': 'hidden',
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#CCC', // Cor do thumb (a parte móvel do scrollbar)
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f1f1f1', // Cor do track (a parte fixa do scrollbar)
          },
        }}
      >
        <DrawerHeader
          sx={{ justifyContent: !open ? 'center' : '', paddingTop: '14px' }}
        >
          {open && (
            <Box
              display='flex'
              alignItems='center'
              width='100%'
              color='#222'
              justifyContent={'space-between'}
            >
              <Typography fontSize={'1rem'} fontWeight={600}>
                Painel
              </Typography>
              <IconButton onClick={handleDrawerClose}>
                <img src={hamburguerDash} alt='open-and-close-icon' />
              </IconButton>
            </Box>
          )}
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            sx={{
              marginLeft: 'auto',
              ...(open && { display: 'none' }),
            }}
          >
            <img src={hamburguerDash} alt='open-and-close-icon' />
          </IconButton>
        </DrawerHeader>

        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            marginBottom: '32px',
            paddingInline: open ? '20px' : '8px',
          }}
        >
          <>
            {(menuTipo === 'produto' ? menuItemsProduct : menuItems).map(
              (item, index) => renderMenuItem(item, index)
            )}
          </>
        </List>

        <Divider
          variant='middle'
          sx={{
            backgroundColor: '##EEE',
            opacity: 0.1,
          }}
        />

        <List
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            paddingInline: open ? '20px' : '8px',
          }}
        >
          {submenuItems.map((item) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton
                component={NavLink}
                target='_blank'
                to={item.to}
                activeClassName='active'
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 1.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 1 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <img src={item.icon} alt={item.text} width='24' height='24' />
                </ListItemIcon>
                <ListItemText
                  primary={item.text}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  )
}
