import React, { useEffect } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import Select from './Select'
import * as listsActions from '../../actions/listsActions'

const TicketSelect = ({
  label = 'Ingresso',
  name,
  errors = {},
  uuidEvento = null,
  handleChange = () => void 0,
}) => {
  const items = useSelector((state) => state.lists.tickets || [])
  const user = useSelector((state) => state.user)

  const dispatch = useDispatch()

  const getItems = () =>
    dispatch(
      listsActions.getLists(
        `/usuarios/${user.uuid}/eventos/${uuidEvento}/bilhetes?limit=100`,
        { list: 'tickets', arg: '' }
      )
    )

  const clearList = () => dispatch(listsActions.clearList('tickets'))

  useEffect(() => {
    if (uuidEvento) {
      getItems()
    }
  }, [uuidEvento])

  useEffect(() => {
    return () => clearList()
  }, [])

  return (
    <Select
      errors={errors}
      label={label}
      name={name}
      onChange={(e) => handleChange(uuidEvento, e.target.value)}
    >
      <option value=''>-- Selecione --</option>
      {items.map((item) => (
        <option key={item.uuid} value={item.uuid}>
          {item.nome}
        </option>
      ))}
    </Select>
  )
}

export default TicketSelect
