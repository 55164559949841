import { Typography, useMediaQuery } from '@mui/material'
import Box from '@mui/material/Box'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { styled } from '@mui/system'
import * as React from 'react'
import { closeIcon } from 'assets/svgs'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import { useState } from 'react'
import TGModal from 'TGComponents/global/TGModal'
import { useEffect } from 'react'

const StyledDrawer = styled(SwipeableDrawer)(({ anchor }) => ({
  '.MuiDrawer-paper': {
    backgroundColor: 'transparent !important',
    boxShadow: 'none !important',
  },

  [`& .MuiDrawer-paperAnchor${capitalize(anchor)}`]: {
    backgroundColor: anchor === 'bottom' ? 'white' : 'transparent',
  },
}))

const SideBarRightContainer = styled(Box)(({ anchor }) => ({
  background: anchor === 'bottom' ? 'white' : 'transparent',
  width: anchor === 'bottom' ? '100%' : 410,
}))

const SideBarBody = styled(Box)(({ anchor, theme }) => ({
  background: 'white',
  borderRadius: '16px',
  overflowY: 'auto',
  cursor: 'auto',
  [theme.breakpoints.down('sm')]: {
    padding: '0',
  },
}))

const CloseIcon = styled('img')({
  '&:hover': {
    filter: 'brightness(0.8)',
    cursor: 'pointer',
    transition: 'filter 0.3s ease-in-out',
  },
})

const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1)

export default function SwipeableTemporaryDrawer({
  title = '',
  titleComponent,
  children,
  isOpen: externalIsOpen,
  onClose: externalOnClose,
  onCloseDrawer,
  rowGap = 3,
  big = false,
  confirmClose = false,
  confirmCloseTitle = '',
  confirmCloseMessage = '',
  padding,
  minHeightSidebar,
  minWidthSidebar,
}) {
  const isMobile = useMediaQuery('(max-width:1080px)')
  const [openModal, setOpenModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const defaultAnchor = isMobile ? 'bottom' : 'right'

  useEffect(() => {
    setIsOpen(externalIsOpen)
  }, [externalIsOpen])

  const handleCloseDrawer = () => {
    setIsOpen(false)
    if (externalOnClose) {
      externalOnClose()
    }
    if (onCloseDrawer) {
      onCloseDrawer()
    }
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    handleCloseDrawer()
  }

  const handleCloseOnlyModal = () => {
    setOpenModal(false)
  }

  const handleClose = () => {
    if (confirmClose && !openModal) {
      setOpenModal(true)
    } else {
      handleCloseDrawer()
    }
  }

  return (
    <div>
      <StyledDrawer
        anchor={defaultAnchor}
        open={isOpen}
        onClose={handleClose}
        onOpen={() => setIsOpen(true)}
      >
        <SideBarRightContainer
          role='presentation'
          onClick={(event) => event.stopPropagation()}
          //onKeyDown={handleClose}
          sx={{ width: '100%', margin: 0 }}
        >
          <SideBarBody
            sx={{
              margin: defaultAnchor === 'bottom' ? '0' : '20px',
              width:
                defaultAnchor === 'bottom'
                  ? '100%'
                  : big
                  ? 756
                  : minWidthSidebar
                  ? minWidthSidebar
                  : 410,
              height: defaultAnchor === 'bottom' ? 'auto' : '97vh',
              borderRadius:
                defaultAnchor === 'bottom' ? '16px 16px 0px 0px' : '16px',
            }}
          >
            <Box
              sx={{
                'overflow': 'auto',
                'maxHeight': 'calc(97vh - 40px)',
                'minHeight': minHeightSidebar || 'calc(100vh - 40px)',
                '&::-webkit-scrollbar': {
                  width: '4px',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#CCC',
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: '#f1f1f1',
                },
              }}
              rowGap={rowGap}
              display={'flex'}
              flexDirection={'column'}
            >
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                padding={
                  padding
                    ? padding
                    : big
                    ? [
                        '20px 20px 0px 20px',
                        '20px 20px 0px 20px',
                        '40px 40px 0px 40px',
                      ]
                    : ['20px 20px 0px 20px', '20px 20px 0px 20px']
                }
                //sx={{
                //  padding: isMobile
                //    ? '20px 20px 0px 20px'
                //    : big
                //    ? ['0px', '0px', '40px', '20px 20px 0px 20px']
                //    : padding
                //    ? padding
                //    : '40px 40px 0px 40px',
                //}}
              >
                {titleComponent ? (
                  titleComponent
                ) : (
                  <Typography
                    variant='h6'
                    fontSize={'1.5rem'}
                    sx={{ fontWeight: 600 }}
                  >
                    {title}
                  </Typography>
                )}

                <CloseIcon
                  onClick={handleClose}
                  src={closeIcon}
                  alt='close-icon'
                />
              </Box>
              <Box px={big ? ['0px', '0px', '0px', '40px'] : '0px'}>
                {children}
              </Box>
            </Box>
          </SideBarBody>
        </SideBarRightContainer>
      </StyledDrawer>
      <TGModal
        onClose={handleCloseOnlyModal}
        open={openModal}
        onConfirm={handleCloseModal}
        icon={'images/trash.png'}
        isConfirmation
        title={confirmCloseTitle}
        message={confirmCloseMessage}
        cancelButtonText='Cancelar'
        confirmButtonText='Sim, sair'
      ></TGModal>
    </div>
  )
}
