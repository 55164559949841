import { Box, Dialog, DialogContent, Typography } from '@mui/material'
import { styled } from '@mui/system'
import TGButton from 'TGComponents/global/TGButton'
import { closeIcon } from 'assets/svgs'

const TGModalContainer = styled(Dialog)(
  ({ theme, maxWidth, paddingContainer }) => ({
    '& .MuiDialog-paper': {
      borderRadius: 16,
      padding: paddingContainer || '10px',
      width: '100%',
      maxWidth: maxWidth || 'sm', // Definindo maxWidth padrão como 'sm' caso não seja fornecido
      [theme.breakpoints.down('sm')]: {
        borderRadius: '16px 16px 0px 0px',
        padding: 0,
        margin: 0,
        marginTop: 'auto',
      },
    },
    '& .MuiDialogContent-root': {
      textAlign: 'center',
      padding: theme.spacing(2),
      margin: 0,

      [theme.breakpoints.down('sm')]: {
        padding: '20px',
        margin: 0,
      },
    },
  })
)
const CloseIcon = styled('img')({
  'position': 'absolute',
  'right': '40px',
  'top': '40px',

  '&:hover': {
    filter: 'brightness(0.8)',
    cursor: 'pointer',
    transition: 'filter 0.3s ease-in-out',
  },
})

const DialogIcon = styled('img')({
  marginTop: '2rem',
  maxWidth: '80px',
  maxHeight: '80px',
})

const TGModalContent = styled(DialogContent)(({ textAlign, padding }) => ({
  textAlign: textAlign || 'center',
  padding: padding || '0',
  marginBlock: '24px',
}))

const TGModalButtonContainer = styled(Box)({
  padding: '16px 20px 14px',
  gap: '1rem',
  display: 'flex',
  justifyContent: 'center',
})

export default function TGModal({
  title,
  message,
  confirmButtonText,
  cancelButtonText,
  onConfirm,
  onClose,
  open,
  icon,
  isConfirmation,
  textAlign,
  children,
  padding,
  maxWidth,
  loading,
  paddingContainer,
  disabledCancelButton = false,
  disabledConfirmButton = false,
  notCancel,
  widthConfirmButton,
}) {
  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm()
    }
    //Tive que desativar para somente chamar pelo pai
    //handleClose()
  }

  return (
    <Box>
      <TGModalContainer
        onClose={handleClose}
        open={open}
        textAlign={textAlign}
        maxWidth={maxWidth}
        paddingContainer={paddingContainer}
      >
        <CloseIcon onClick={handleClose} src={closeIcon} alt='close-icon' />
        <Box display='flex' justifyContent='center' mb={3}>
          {icon && <DialogIcon src={icon} alt='icon' />}
        </Box>
        <TGModalContent style={{ padding: padding }}>
          <Typography mb='16px' fontSize='20px' fontWeight='600'>
            {title}
          </Typography>
          <Typography color='#555' fontSize='0.875rem'>
            {message}
          </Typography>
          {children}
        </TGModalContent>
        {isConfirmation && (
          <TGModalButtonContainer>
            {!notCancel && (
              <TGButton
                bgcolor='#fff'
                variant='outlined'
                sx={{
                  paddingInline: '40px',
                  color: '#0097D8',
                }}
                onClick={handleClose}
                loading={loading}
                disabled={loading || disabledCancelButton}
              >
                {cancelButtonText}
              </TGButton>
            )}
            <TGButton
              variant='contained'
              autoFocus
              onClick={handleConfirm}
              sx={{
                color: 'white',
                paddingInline: '40px',
                width: widthConfirmButton,
              }}
              loading={loading}
              disabled={loading || disabledConfirmButton}
            >
              {confirmButtonText}
            </TGButton>
          </TGModalButtonContainer>
        )}
      </TGModalContainer>
    </Box>
  )
}
