import { useEffect, useRef } from 'react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { useDispatch, useSelector } from 'react-redux'

import AllowedComponent from '../components/auth/AllowedComponent'
import * as notificationsActions from './../actions/notificationActions'

import './AuthRoutes.css'

import { Box } from '@mui/material'

import TGSideBar from 'TGComponents/layout/TGSideBar/TGSideBar'
import TGTopBar from 'TGComponents/layout/TGTobBar/TGTopBar'
import menuItems from 'data/navs/MenuItems'

const AuthRoutes = () => {
  const navigate = useNavigate()

  const location = useLocation()

  let loc = location.pathname.toLowerCase().replace('/admin/', '')

  const {
    type,
    message,
    show,
    redirectTo,
    loggedIn,
    complete,
    //menus,
    user,
    isAdm,
    meeting,
    sidemenuOpen,
  } = useSelector((state) => ({
    loggedIn: state.user.uuid,
    complete: state.user.complete || false,
    user: state.user,
    isAdm: state.user?.super_admin || false,
    meeting: state.meeting,
    //menus: state.user.menus,
    type: state.notification.type,
    message: state.notification.message,
    show: state.notification.show,
    redirectTo: state.notification.redirectTo,
    sidemenuOpen: state.application.sidemenuOpen,
  }))
  const isUserAllowed = (menu, user, isAdm) => {
    const isAdmMenu = menu?.adm ?? true
    if (isAdm && isAdmMenu) {
      return true
    } else {
      const roles = user?.roles || []
      const rolesPermitidas = menu?.roles || []
      const boEvo = menu?.evo || false
      const exclusivoEvo = menu?.exclusivoEvo || false
      const boUsuarioVariasProdutoras = user?.boUsuarioVariasProdutoras || false

      //empresas dos users que podem acessar o menu Indicações (evo)
      const empresas_evo = [
        56, 58, 11, 57, 19, 61, 50, 60, 49, 63, 59, 9, 45, 62,
      ]

      const _isPermitido = roles.find((r) =>
        rolesPermitidas.find((rp) =>
          !isAdm
            ? boEvo &&
              (empresas_evo.includes(r.id_produtora) ||
                boUsuarioVariasProdutoras) &&
              !exclusivoEvo
              ? true
              : exclusivoEvo && r.id_produtora == 9
              ? true
              : (r.super_admin || !boEvo) && r.slug === rp
            : r?.adm
        )
      )
      return _isPermitido
    }
  }

  const filterMenuItems = (items, user, isAdm) => {
    
    const roles = user?.roles || []
    return items
      .filter((item) => isUserAllowed(item, user, isAdm))
      .map((item) => {
        if (item.children) {
          item.children.find((r,indice)=>{
            item.children[indice].to = r.to.replace('#UUIDPRODUTORA#',roles?.[0]?.uuid_produtora+"&c=1");
          });
          return {
            ...item,
            children: filterMenuItems(item.children, user, isAdm),
          }
        }
        return item
      })
  }

  const dispatch = useDispatch()

  const notify = () => {
    const options = {
      position: 'top-right',
      theme: 'colored',
      onClose: () => dispatch(notificationsActions.clear()),
    }

    if (type === 'success') {
      toast.success(message, {
        ...options,
      })
    } else if (type === 'warning') {
      toast.warning(message, {
        ...options,
      })
    } else if (type === 'info') {
      toast.info(message, {
        ...options,
      })
    } else {
      toast.error(message, {
        ...options,
      })
    }
  }

  useEffect(() => {
    if (show) {
      if (redirectTo) {
        dispatch(notificationsActions.clearRedirect())
        navigate(redirectTo, { replace: true })
      } else {
        notify()
      }
    }
  }, [show, redirectTo])

  const filteredMenuItems = filterMenuItems(menuItems, user, isAdm)

  return loggedIn ? (
    <Box
      height={'100%'}
      width='100%'
      position={'relative'}
      backgroundColor={{ xs: '#fff', sm: '#EEEEEE' }}
    >
      <TGTopBar sx={{ zIndex: 999, maxWidth: '100vw' }} />
      <Box
        mt={'96px'}
        padding={{ xs: '0', md: '20px 0 20px' }}
        backgroundColor={{ xs: '#fff', lg: '#EEEEEE' }}
      >
        <Box display='flex' width='100%'>
          <TGSideBar menuItems={filteredMenuItems} />
          <Box
            width='100%'
            display='flex'
            flexDirection='column'
            overflow='auto'
          >
            <Box flex='1'>
              <AllowedComponent permission={loc} redirect={true}>
                <Outlet />
              </AllowedComponent>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  ) : (
    <Navigate to='/login' />
  )
}

export default AuthRoutes
