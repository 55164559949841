// palette.js
// Exemplos para utilizar a nova palheta de cor <Box bgcolor={'primary.10'}/>
const palette = {
  mode: 'light',
  neutral: {
    main: '#111111',
    10: '#111111',
    20: '#222222',
    30: '#555555',
    40: '#999999',
    50: '#CCCCCC',
    60: '#EEEEEE',
    70: '#F9F9F9',
    80: '#FFFFFF',
  },
  success: {
    main: '#3ABD52',
    10: '#3ABD52',
    light: '#EBFFEE',
    80: '#EBFFEE',
  },
  alert: {
    main: '#F2C200',
    10: '#F2C200',
    light: '#FFFCEB',
    80: '#FFFCEB',
  },
  error: {
    main: '#FF4848',
    10: '#FF4848',
    light: '#FFEBEE',
    80: '#FFEBEE',
  },
  primary: {
    main: '#00B2FF',
    10: '#00B2FF',
    light: '#EBF9FF',
    80: '#EBF9FF',
  },
  text: {
    primary: '#272827',
    main: '#272827',
  },
}

export default palette
