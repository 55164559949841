import { createTheme } from '@mui/material/styles'
import palette from './pallete'
import typography from './typography'

// MUI CONFIGURATION
export const muiTheme = createTheme({
  typography: typography,
  palette: palette,
  components: {
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave',
        variant: 'rounded',
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#555',
          '&:hover': {
            backgroundColor: '#E6F5FB',
            color: '#222222',
          },
          '&.Mui-selected': {
            backgroundColor: '#E6F5FB',
            color: '#222222',
          },
        },
      },
    },
  },
})
