import React from 'react'
import { ToastContainer } from 'react-toastify'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import { HashRouter as Router, Navigate, Route, Routes } from 'react-router-dom'

import AuthRoutes from './pages/AuthRoutes'
import PublicRoutes from './pages/PublicRoutes'
import Dashboard from './pages/admin/dashboard/Dashboard'
import Producer from './pages/admin/producer/ProducerAdd'
import Login from './pages/public/Login'
import Recover from './pages/public/Recover'
import Register from './pages/public/Register'
import { useSelector } from 'react-redux'

import Logout from './pages/public/Logout'
import LazyWrapper from './pages/LazyWrapper'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

//import Pessoa from './pages/public/Pessoa'
import { muiTheme } from 'TGComponents/theme/index'
import AlertsProvider from 'providers/alerts-context'

const App = () => {
  const complete = useSelector((state) => state.user.complete || false)

  return (
    <AlertsProvider>
      <ThemeProvider theme={muiTheme}>
        <>
          <ToastContainer />
          <Router>
            <Routes>
              <Route exact path='/' element={<Navigate to='/admin' />} />

              {/* Rota admin, o componente AuthRoutes é o layout */}
              <Route path='/admin' element={<AuthRoutes />}>
                <Route
                  index
                  element={complete ? <Dashboard /> : <Producer />}
                />
                <Route path='*' element={<LazyWrapper />} />
              </Route>

              <Route path='login' element={<PublicRoutes />}>
                <Route
                  index
                  element={
                    <GoogleReCaptchaProvider reCaptchaKey='6LdKWHohAAAAAK9YNeeXDLpWCDITxXgcHaGgz2tQ'>
                      <Login />
                    </GoogleReCaptchaProvider>
                  }
                />
              </Route>
              <Route path='register' element={<PublicRoutes />}>
                <Route
                  index
                  element={
                    <GoogleReCaptchaProvider reCaptchaKey='6LdKWHohAAAAAK9YNeeXDLpWCDITxXgcHaGgz2tQ'>
                      <Register />
                    </GoogleReCaptchaProvider>
                  }
                />
              </Route>
              <Route path='recover' element={<PublicRoutes />}>
                <Route
                  index
                  element={
                    <GoogleReCaptchaProvider reCaptchaKey='6LdKWHohAAAAAK9YNeeXDLpWCDITxXgcHaGgz2tQ'>
                      <Recover />
                    </GoogleReCaptchaProvider>
                  }
                />
              </Route>
              <Route path='/logout' element={<Logout />} />
            </Routes>
          </Router>
        </>
      </ThemeProvider>
    </AlertsProvider>
  )
}

export default App
